import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { WhiteBtn } from "../components/LearnMore";
import { HeroWrap } from "../components/HeroWrap";
import evalute_t from "../assets/images/evolution_t.png";
import evalute_logo from "../assets/images/evolution_logo.png";
import validate_t from "../assets/images/validate_t.png";
import validate_logo from "../assets/images/validate_logo.png";
import classic_t from "../assets/images/classic_t.png";
import classic_logo from "../assets/images/classic_logo.png";
import bbl_logo from "../assets/images/bbl_logo.png";
import checked from "../assets/images/blue_checked.png";
import award_1 from "../assets/images/award_1.png";
import award_2 from "../assets/images/award_2.png";
import award_3 from "../assets/images/award_3.png";
import award_4 from "../assets/images/award_4.png";
import workplace from "../assets/images/workplace.png";
import education from "../assets/images/education.png";
import professor_1 from "../assets/images/professor_1.png";
import professor_2 from "../assets/images/professor_2.png";
import professor_3 from "../assets/images/professor_3.png";
import huddersfield from "../assets/images/university_huddersfield.png";
import quanta from "../assets/images/quanta.png";
import kent from "../assets/images/university_kent.png";
import lancaster from "../assets/images/lancaster_university.png";
import leeds from "../assets/images/university_leeds.png";
import leicester from "../assets/images/university_leiceter.png";
import { Label, Select } from "flowbite-react";
import { OptionItem } from "../components/OptionItem";
import { HeaderBtn } from "../components/layouts/Header";
import { VideoBox } from "../components/VideoBox";

export const progsList = [
  "Evolution",
  "Validate",
  "Classic",
];
export const ageList = ["0 - 25", "25 - 75", "75 - 200", "200 - 500", "500 +"];

export type contactFrm = {
  InputName: string;
  InputEmail: string;
  InputPhone: string;
  CompanyName: string;
  CompanyPhone: string;
  InputMessage: string;
};
type IProp = {
  img: string;
  title: string;
  description: string;
  url: string;
};
type IItem = {
  src: string;
  logo: string;
  title: string;
  description: string;
};

export const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const ItemBox = ({ img, title, description, url }: IProp) => {
  const navigate = useNavigate();
  return (
    <div className="xl:p-5 md:p-2 max-md:p-3 border md:border-my-blue max-md:border-x-0 max-md:border-[#2E90F2] md:rounded-xl max-md:w-full xl:w-[365px] md:w-[250px] grid gap-y-3">
      <img src={img} alt="" className="xl:w-[388px] md:w-[220px]" />
      <h6 className="text-sm font-bold xl:text-2xl text-my-gray max-md:text-center max-md:text-[#2E90F2] max-md:text-xl">
        {title}
      </h6>
      <p className="text-sm font-light xl:text-base text-my-gray">
        {description}
      </p>
      <div>
        <button
          className="px-5 py-2 text-sm font-bold text-white rounded-md header_btn xl:text-base max-md:w-full"
          onClick={() => {
            navigate(url);
          }}
        >
          Learn More
        </button>
      </div>
    </div>
  );
};
const ItemBox2 = ({ src, logo, title, description }: IItem) => {
  const navigate = useNavigate();
  return (
    <div className="xl:w-[452px] max-xl:w-full max-md:flex max-md:flex-col-reverse border border-[#2D8DED] rounded-lg xl:p-4 max-xl:p-5">
      <div className="grid gap-y-4 content-between">
        <div className="space-y-4">
          <div className="flex justify-center">
            <img src={src} alt="" />
          </div>
          <div className="flex justify-center">
            <img src={logo} alt="" className="xl:w-[388px] md:w-[340px]" />
          </div>
          <h6 className="mt-3 font-bold text-center xl:text-2xl max-md:text-[#2E90F2] max-md:text-xl max-md:mb-1">
            {title}
          </h6>
          <p className="font-light text-center text-md xl:text-base px-4">
            {description}
          </p>
        </div>
        <div className="flex justify-center">
          <button 
            className="learn_more_bg px-6 py-2 rounded-lg"
            onClick={() => {
              navigate("/Contact");
            }}
          >
              Book Demo
          </button>
        </div>
      </div>
    </div>
  );
};
export const BSD = () => {
  const navigate = useNavigate();
  const [selectedProgs, setSelectedProgs] = useState<string[]>([]);
  const toggleProgs = (val: string) => {
    if (selectedProgs.includes(val)) {
      setSelectedProgs(selectedProgs.filter((item) => item !== val));
    } else {
      setSelectedProgs([...selectedProgs, val]);
    }
  };
  return (
    <div className="space-y-5 min_contact_wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Business Simulation | ARSOME Technology</title>
        <meta
          name="description"
          content="The next generation of ethical and smart business people are youth in
          our community and we need to provide them the tools to succeed,
          whether as an entrepreneur or if they join a company. Our advanced
          business learning and simulation software puts youth right into a
          business environment, with meaningful and authentic business
          situations to measure risk and opportunity. Depending on the
          particular needs, youth can experience what it’s like to run a
          business, like inventory management, financial modeling, sales, and
          organization. The program can be run for 1-student or a group of
          students depending on the need."
        />
      </Helmet>
      <HeroWrap className="md:bsd_hero_bg max-md:hidden">
        <h6 className="font-bold md:text-xl 2xl:text-5xl">
          Business Simulation
        </h6>
        <p className="text-sm 2xl:text-lg">
          The next generation of ethical and smart business people are
          youth in our community. We're responsible for providing them with marketing,
          finance, marketing and management tools to succeed. Our advanced business
          simulation programs allow youth to experience what it’s like to run a
          business, like inventory management, financial modeling, sales, and teamwork.
          The program can be for 1-student or a group of students, with teacher
          instructional portals and data dashboards.
        </p>
        <div className="flex gap-3">
          {/* <WhiteBtn
            title="Are We A Fit?"
            onAction={() => {
              navigate("/Are_We_A_Fit");
            }}
          /> 
          <button
            className="px-4 py-2 font-bold bg-white rounded-lg max-md:w-full text-my-gray xl:text-base"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Case Study
          </button> */}
          <WhiteBtn
            title="Let's Connect"
            onAction={() => {
              navigate("/Contact");
            }}
          />
        </div>
      </HeroWrap>
      <HeroWrap className="md:bsd_hero_bg md:hidden max-md:block">
        <h6 className="font-bold md:text-xl 2xl:text-5xl text-[#2E90F2] text-center">
          Business Simulation
        </h6>
        <p className="text-base 2xl:text-lg text-[#383231]">
          The next generation of ethical and smart business people are
          youth in our community. We're responsible for providing them with marketing,
          finance, marketing and management tools to succeed. Our advanced business
          simulation programs allow youth to experience what it’s like to run a
          business, like inventory management, financial modeling, sales, and teamwork.
          The program can be for 1-student or a group of students, with teacher
          instructional portals and data dashboards.
        </p>
        <div className="flex gap-3">
          {/* <HeaderBtn
            title="Are We A Fit?"
            onAction={() => {
              navigate("/Are_We_A_Fit");
            }}
          /> */}
          {/* <button
            className="px-4 py-2 font-bold bg-white rounded-lg max-md:w-full text-my-gray xl:text-base"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Case Study
          </button> */}
          <HeaderBtn
            title="Let's Connect"
            onAction={() => {
              navigate("/Contact");
            }}
          />
        </div>
      </HeroWrap>
      <section>
        <div className="flex justify-center max-xl:flex-col xl:space-x-6 max-xl:px-4 max-xl:space-y-5">
          <ItemBox2
            src={evalute_t}
            logo={evalute_logo}
            title="Evolution"
            description="Drive experiential learning with the advanced online business simulation, Evolution. Evolution enables learners to start, manage and grow a bicycle manufacturing company for up to 10 simulated years (40 turns). By making decisions, executing their plans, and analyzing and managing the real-time consequences to their virtual company, learners develop key business knowledge and skills."
          />
          <ItemBox2
            src={validate_t}
            logo={validate_logo}
            title="Validate"
            description="Support learners to develop startup and project ideas with Validate individually or in teams. Validate allows learners to ideate, plan, test and share their entrepreneurial projects. Educators can monitor project progress and provide feedback in real-time. By using a lean startup methodology, learners develop key business skills and increase their chances of entrepreneurial success."
          />
          <ItemBox2
            src={classic_t}
            logo={classic_logo}
            title="Classic"
            description="Classic is an original offline small business simulation ideal for in-class education. Encourage learners to plan, execute and evaluate their own business strategies over 36 monthly cycles/turns. By using Classic, learners experience all aspects of starting, managing and growing a new company whilst enhancing their business acumen and commercial awareness."
          />
        </div>
      </section>
      <section className="space-y-5">
        <h6 className="text-[#4387EB] font-bold xl:text-4xl text-center">
          Bringing Business Learning to Life
        </h6>
        <div className="flex md:flex-row max-md:flex-col justify-center xl:space-x-8 md:space-x-5 max-md:space-y-5 max-md:px-4 md:items-center">
          {/* <div>
            <img src={bbl_logo} alt="" className="xl:w-[690px] md:w-[387px]" />
          </div> */}
          <div className="flex justify-center">
            <VideoBox
              className="w-[360px] md:w-[354px] xl:w-[690px] h-[206px] md:h-[226px] xl:h-[458px]"
              url="https://youtu.be/lVOo6tBC8hk"
            />
          </div>
          <div className="xl:w-[690px] md:w-[397px] xl:p-8 p-6 border border-[#2D8DED] rounded-lg space-y-5">
            <h6 className="font-semibold xl:text-xl md:text-base">
              SimVenture business simulations and entrepreneurship learning
              tools help you bring business learning to life.
            </h6>
            <p className="xl:text-xl font-light text-base">
              Used by education institutions and workplace training providers in
              over 40 countries, our range of SimVenture education technology
              solutions are proven to:
            </p>
            <div className="space-y-3">
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt=""/>
                  </div>
                </div>
                <div>
                  <p className="xl:text-xl text-base font-light grow ">
                    Improve business skills and entrepreneurship understanding
                  </p>
                </div>                
              </li>
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt=""  />
                  </div>
                </div>
                <p className="xl:text-xl text-base font-light ">
                  User Motivations & Incentives
                </p>
              </li>
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt="" />
                  </div>
                </div>
                <p className="xl:text-xl text-base font-light ">Project Schedule</p>
              </li>
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt="" />
                  </div>
                </div>
                <p className="xl:text-xl text-base font-light ">Market Research</p>
              </li>
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt="" />
                  </div>
                </div>
                <p className="xl:text-xl text-base font-light ">Risk Mitigation</p>
              </li>
              <li className="flex space-x-1">
                <div className="flex items-center w-[30px]">
                  <div>
                    <img src={checked} alt="" />
                  </div>
                </div>
                <p className="xl:text-xl text-base font-light ">Technology Requirements</p>
              </li>
            </div>
          </div>
        </div>
      </section>
      <section className="space-y-5">
        <h6 className="text-[#4387EB] font-bold xl:text-4xl text-center">
          What Our Clients Say
        </h6>
        <div className="flex md:flex-row max-md:flex-col md:px-4 justify-center md:space-x-8 max-md:space-y-4 max-md:px-4">
          <div className="xl:w-[690px] border border-[#2D8DED] p-8 rounded-lg space-y-3">
            <h6 className="text-[#383231] text-center font-semibold text-xl">
              What Rebecca Smith says about Evolution
            </h6>
            <div className="flex justify-center">
              <VideoBox
                className="w-[320px] md:w-[300px] xl:w-[623px] md:h-[226px] xl:h-[350px]"
                url="https://youtu.be/Qlm5C3DtOFw"
              />
            </div>
            <p className="text-xl font-light text-center ">
              Discover how Rebecca Smith has used our advanced business
              simulation with University of Kent students.
            </p>
          </div>
          <div className="xl:w-[690px] border border-[#2D8DED] p-8 rounded-lg space-y-3">
            <h6 className="text-[#383231] text-center font-semibold text-xl">
              What Dr Amanda Berry says about Validate
            </h6>
            <div className="flex justify-center">
              <VideoBox
                className="w-[320px] md:w-[300px] xl:w-[623px] md:h-[226px] xl:h-[350px]"
                url="https://youtu.be/HUaeITYsY2I"
              />
            </div>
            <p className="text-xl font-light text-center ">
              Find out how Dr Amanda Berry has used our startup ideas platform
              with Loughborough University students.
            </p>
          </div>
        </div>
      </section>
       <section className="space-y-5">
        <h6 className="text-center text-[#2D8DED] font-bold xl:text-4xl">
          From a Multi Award Winning Team
        </h6>
        <div className="flex justify-center xl:space-x-3">
          <div>
            <div className="flex item-center">
              <img src={award_1} alt="" />
            </div>
          </div>
          <div>
            <div className="flex item-center">
              <img src={award_2} alt="" />
            </div>
          </div>
          <div>
            <div className="flex item-center">
              <img src={award_3} alt="" />
            </div>
          </div>
          <div>
            <div className="flex item-center">
              <img src={award_4} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="space-y-5">
        <h6 className="text-center text-[#2D8DED] font-bold xl:text-4xl">
          Apply our Entrepreneurship Learning Tools & Business Simulations
        </h6>
        <div className="flex md:flex-row flex-col justify-center md:space-x-5 xl:space-x-8 max-md:px-3 max-md:space-y-5">
          <div className="xl:w-[690px] border border-[#2D8DED] p-8 rounded-lg space-y-3">
            <div className="flex justify-center">
              <img src={workplace} alt="" />
            </div>
            <h6 className="text-center font-bold text-[#383231] text-3xl">
              Workplace
            </h6>
            <p className="text-xl font-light text-center ">
              From multi-national banks to entrepreneurial public bodies, people
              choose our business simulation games and advanced digital learning
              solutions for many reasons.
            </p>
            <p className="text-xl font-light text-center ">
              <a href="https://simventure.com/products/evolution/" className="underline" target={"_blank"}>SimVenture Evolution</a> is often the backbone of online business
              competitions and engaging team-building events. To help develop
              commercial skills, foster innovative thinking and develop new
              ideas, people use the business simulation or
              incorporate <a href="https://simventure.com/products/validate/workplace-training/" className="underline" target={"_blank"}>SimVenture Validate</a>.
            </p>
          </div>
          <div className="xl:w-[690px] border border-[#2D8DED] p-8 rounded-lg space-y-3">
            <div className="flex justify-center">
              <img src={education} alt="" />
            </div>
            <h6 className="text-center font-bold text-[#383231] text-3xl">
              Education
            </h6>
            <p className="text-xl font-light text-center ">
              Hundreds of universities, colleges and schools worldwide use our
              advanced digital learning solutions to increase student engagement
              and progress learning quickly.
            </p>
            <p className="text-xl font-light text-center ">
              Our business simulations and business learning platforms are
              versatile, easy to use, and fit for purpose. They are also
              extremely flexible and can easily be embedded into the curriculum
              or used as extra-curricular business learning tools.
            </p>
          </div>
        </div>
      </section>
     <section className="flex max-md:flex-col md:flex-row justify-center md:space-x-5 max-md:space-y-5 max-md:px-3">
        <div className="xl:w-[453px] border border-[#2D8DED] p-8 rounded-lg grid place-content-between gap-y-3">
          <div className="flex justify-center">
            <img src={professor_1} alt="" />
          </div>
          <h6 className="text-center font-bold text-[#383231] text-3xl">
            Jyoti Bhardwaj
          </h6>
          <p className="xl:text-xl font-light text-center text-[#383231]">
            “At Edinburgh Napier University we use SimVenture as an enjoyable
            and participative way for our computer science students. Students
            learn about business, while working in groups and developing their
            communication and presentation skills. We began ten years ago with
            SimVenture Classic and progressed to SimVenture Evolution. Our
            students cite both business simulation games as a fun way to learn
            and a highlight of their first-year experience.”
          </p>
          <p className="xl:text-xl text-center text-[#383231] font-bold">
            Jyoti Bhardwaj | Programme leader, Lecturer and Teaching Fellow
          </p>
        </div>
        <div className="xl:w-[453px] border border-[#2D8DED] p-8 rounded-lg grid place-content-between gap-y-3">
          <div className="flex justify-center">
            <img src={professor_2} alt="" />
          </div>
          <h6 className="text-center font-bold text-[#383231] text-3xl">
            Dr Christina Raphaëlle Haldane
          </h6>
          <p className="xl:text-xl font-light text-center text-[#383231]">
            “I recently developed an online entrepreneurship course for
            university student artists at the Fountain School of Performing
            Arts. By using SimVenture Validate my students had the opportunity
            to explore key learning in a vibrant, interactive, and user-friendly
            way. The platform also provided students with essential experiential
            learning. My students really enjoy working with SimVenture
            Validate.”
          </p>
          <p className="xl:text-xl text-center text-[#383231] font-bold">
            Dr Christina Raphaëlle Haldane  | Assistant Professor Applied Voice
          </p>
          <p className="xl:text-xl text-center text-[#383231] font-bold">
            Fountain School of Performing Arts, Dalhousie, Canada
          </p>
        </div>
        <div className="xl:w-[453px] border border-[#2D8DED] p-8 rounded-lg grid place-content-between gap-y-3">
          <div>
            <div className="flex justify-center">
              <img src={professor_3} alt="" />
            </div>
            <h6 className="text-center font-bold text-[#383231] text-3xl">
              Dr Bidyut Baruah
            </h6>
          </div>

          <p className="xl:text-xl font-light text-center text-[#383231]">
            “Students have made really good use of SimVenture Evolution and
            SimVenture Validate within our Engineering Department at the
            University of York. Both products have brought learning to life.
            Students have not only developed business and entrepreneurship
            skills but have become more curious and better able to work in
            teams.”
          </p>
          <p className="xl:text-xl text-center text-[#383231] font-bold">
            Dr Bidyut Baruah <br />
            Lecturer in Engineering Management, University of York
          </p>
        </div>
      </section>
      <section className="flex flex-col md:flex-row justify-center md:space-x-4 max-md:space-y-4 px-3 ">
        <div className="flex justify-center space-x-3">
          <div className="flex items-center">
            <div>
              <img src={huddersfield} alt="" />
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <img src={quanta} alt="" />
            </div>
          </div>
          </div>
          <div className="flex justify-center space-x-3">
            <div className="flex items-center">
              <div>
                <img src={kent} alt="" />
              </div>
            </div>
            <div className="flex items-center">
            <div>
                <img src={lancaster} alt="" />
              </div>
            </div>
        </div>
        <div className="flex justify-center space-x-3">
        
          <div className="flex items-center">
            <div>
              <img src={leeds} alt="" />
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <img src={leicester} alt="" />
            </div>
          </div>
        </div>

      </section>
       <section className="py-5 fit_wrap xl:py-8 md:px-4 ">
        <div className="flex justify-center">
          <div className="grid gap-3 md:grid-cols-2">
            <div className="flex items-center md:mx-3">
              <div className="md:w-[382px] xl:w-[687px] grid gap-3 max-md:w-[344px]">
                <h5 className="font-semibold !leading-normal max-md:text-xl max-md:text-center xl:text-5xl md:text-2xl">
                  <strong>Interested</strong> in learning more or receiving a
                  free sample program? Reach out today.
                </h5>
              </div>
            </div>
            <div className="xl:w-[542px] md:w-[342px] bg-white rounded-lg p-3 md:mx-3 max-md:w-[344px]">
              <div className="grid gap-3">
                <h6 className="text-base font-bold xl:text-4xl md:text-2xl text-my-blue mt-8">
                  Request a Quote
                </h6>
                <h6 className=" text-my-gray">Program Selection</h6>
                <div className="flex flex-wrap gap-2 xl:gap-3 ">
                  {progsList.map((it, idx) => (
                    <OptionItem
                      title={it}
                      key={idx}
                      selected={selectedProgs.includes(it)}
                      onClick={toggleProgs}
                    />
                  ))}
                </div>
                <div>
                  <div className="block mb-2">
                    <Label value="Class Size" />
                  </div>
                  <Select required>
                    {ageList.map((it, idx) => (
                      <option value={it} key={idx}>
                        {it}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <input
                    placeholder="Full Name*"
                    className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue text-my-gray"
                  />
                </div>
                <div>
                  <input
                    placeholder="Phone Number (optional)"
                    className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue text-my-gray"
                  />
                </div>
                <div>
                  <input
                    placeholder="Email*"
                    className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-my-blue text-my-gray"
                  />
                </div>
                <div>
                  <div className="block mb-2">
                    <Label value="Order Note*" />
                  </div>
                  <textarea
                    placeholder=""
                    rows={3}
                    className="w-full p-2 border-2 rounded-md border-my-blue text-my-gray"
                  />
                </div>
                <div className="max-md:flex max-md:justify-center">
                  <button className="px-5 py-2 rounded-md header_btn max-md:w-full w-[250px]">
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </div>
  );
};
