import { LinkFooterItem } from "./Layout";
import org_log from "../../assets/images/company_logo.webp";
import venture_logo from "../../assets/images/venture_logo.webp";
import n_cms from "../../assets/images/n_cms_logo.webp";
import n_dia from "../../assets/images/ndia_log.webp";
import pmi_badge from "../../assets/images/PMI_badge.svg";
import pmp_badge from "../../assets/images/PMP_badge.svg";
import usa_flag from "../../assets/images/usa_flag.svg";
import israel_flag from "../../assets/images/Israel_flag.svg";
import ukrain_flag from "../../assets/images/ukraine_flag.svg";

export const Footer = () => {
  return (
    <footer
      className={`footer_wrap_mobile py-[40px] md:footer_wrap px-3 md:px-0 mt-4xl xl:mt-[40px] mt-5 space-y-8`}
    >
      <div className="flex xl:flex-row max-xl:flex-col max-xl:space-y-5 xl:justify-center xl:space-x-5">
        <div className="xl:space-y-4 xl:w-[40vw]">
          <div className="flex justify-center ">
            <div className="flex justify-center">
              <img src={org_log} alt="" className="w-[159px] xl:w-[200px]" />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="w-[350px] md:w-[85vw] xl:w-full text-center md:text-sm xl:text-base">
              ARSOME Technology develops personalized AR/VR training and education programs
              for workforce personnel. Founded by a student and his professor in 2017,
              ARSOME Technology exceeds expectations, inspires the uninventive, and
              tirelessly outperforms for clients and partners.
            </p>
          </div>
        </div>
        <div className="xl:w-[7px] xl:bg-[#FFD700] max-xl:flex max-xl:justify-center">
          <div className="max-xl:w-[20vw] max-md:w-[150px] max-xl:h-[7px] max-xl:bg-[#FFD700]"></div>
        </div>
        <div className="xl:space-y-4 xl:w-[40vw]">
          <div className="flex justify-center ">
            <div className="flex justify-center">
              <img
                src={venture_logo}
                alt=""
                className="w-[159px] xl:w-[200px]"
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="w-[350px] md:w-[85vw] xl:w-full text-center md:text-sm xl:text-base">
              ARSOME Ventures, the investment arm of ARSOME
              Technology, makes strategic investments in early-stage companies with
              synergistic technology, expertise, and determination. ARSOME Ventures looks
              for awesome Founders building industry disruptive products. 
              <a
                href="https://arsomeventures.com"
                target="_blank"
                rel="noreferrer"
                className="font-bold underline cursor"
              >
                Learn more today.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <h3 className="text-center md:w-[85vw]">
          <b>
            ©{new Date().getFullYear()} All Rights Reserved. Powered by ARSOME
            Technology Group LLC
          </b>
        </h3>
      </div>
      <div className="flex mt-3 space-x-5 xl:px-8 md:px-5 max-md:justify-center max-md:flex-wrap max-md:space-y-2">
        <div className="flex items-center">
          <img src={n_cms} alt="" width={99} />
        </div>
        <div className="flex items-center">
          <img src={n_dia} alt="" width={99} />
        </div>
        <div className="flex items-center">
          <img src={pmi_badge} alt="" width={50} />
        </div>
        <div className="flex items-center">
          <img src={pmp_badge} alt="" width={50} />
        </div>
        <img src={usa_flag} alt="" />
        <img src={israel_flag} alt="" />
        <img src={ukrain_flag} alt="" />
      </div>
    </footer>
  );
};
