import { PropsWithChildren, useEffect, useRef, useState } from 'react';
type Props = {
  contentCls: string;
  scrollbarCls: string;
};
const Scrollbar = ({
  children,
  contentCls,
  scrollbarCls,
}: PropsWithChildren<Props>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const [thumbWidth, setThumbWidth] = useState(20);
  const [isDragging, setIsDragging] = useState(false);
  const [scrollStartPosition, setScrollStartPosition] = useState<number>(0);
  const [initialContentScrollLeft, setInitialContentScrollLeft] =
    useState<number>(0);

  const handleResize = () => {
    if (scrollTrackRef.current && contentRef.current) {
      const { clientWidth: trackSize } = scrollTrackRef.current;
      const { clientWidth: contentVisible, scrollWidth: contentTotalWidth } =
        contentRef.current;
      // console.log(contentVisible, contentTotalWidth, trackSize);
      setThumbWidth(
        Math.max((contentVisible / contentTotalWidth) * trackSize, 20)
      );
    }
  };

  const handleThumbPosition = () => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }

    const { scrollLeft: contentLeft, scrollWidth: contentWidth } =
      contentRef.current;
    const { clientWidth: trackWidth } = scrollTrackRef.current;

    let newLeft = (contentLeft / contentWidth) * trackWidth;
    newLeft = Math.min(newLeft, trackWidth - thumbWidth);

    const thumb = scrollThumbRef.current;
    requestAnimationFrame(() => {
      thumb.style.left = `${newLeft}px`;
    });
  };

  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize();
      });
      observer.current.observe(content);
      content.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(content);
        content.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, []);

  const handleThumbMousedown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollStartPosition(e.clientX);
    if (contentRef.current)
      setInitialContentScrollLeft(contentRef.current.scrollLeft);
    setIsDragging(true);
  };

  const handleThumbMouseup = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleThumbMousemove = (e: MouseEvent) => {
    if (contentRef.current) {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        const {
          scrollWidth: contentScrollWidth,
          clientWidth: contentClientWidth,
        } = contentRef.current;

        const deltaX =
          (e.clientX - scrollStartPosition) * (contentClientWidth / thumbWidth);

        const newScrollLeft = Math.min(
          initialContentScrollLeft + deltaX,
          contentScrollWidth - contentClientWidth
        );

        contentRef.current.scrollLeft = newScrollLeft;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
    };
  }, []);

  const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { current: track } = scrollTrackRef;
    const { current: content } = contentRef;
    if (track && content) {
      const { clientX } = e;
      const target = e.target as HTMLDivElement;
      const rect = target.getBoundingClientRect();
      const trackLeft = rect.left;
      const thumbOffset = -(thumbWidth / 2);
      const clickRatio =
        (clientX - trackLeft + thumbOffset) / track.clientWidth;
      const scrollAmount = Math.floor(clickRatio * content.scrollWidth);
      content.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div>
      <div
        className={`content ${contentCls}`}
        id="custom-scrollbars-content"
        ref={contentRef}
      >
        {children}
      </div>
      <div className={`scrollbar ${scrollbarCls}`}>
        <div
          className="track"
          ref={scrollTrackRef}
          onClick={handleTrackClick}
          style={{ cursor: isDragging ? 'grabbing' : undefined }}
        ></div>  
        <div
          className="thumb h-full"
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
          style={{
            width: `${thumbWidth}px`,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        ></div>
      </div>
    </div>
  );
};

export default Scrollbar;
