'use client';
interface Props {
  title: string;
  description: string;
}
export const CenterDesc = (props: Props) => {
  const { title, description } = props;
  return (
    <div className="p-5 space-y-4 md:center_desc_wrap max-md:border max-md:border-y max-md:border-[#2E90F2]">
      <h6 className=" text-md md:text-xl xl:text-[40px]  font-bold text-center text-[#2E90F2] md:text-white max-md:text-xl">
        {title}
      </h6>
      <div className="flex justify-center">
        <p className="px-4 md:px-8 xl:px-0 text-center md:text-md xl:text-lg xl:w-[950px] max-md:text-[#383231] max-md:font-light">
          {description}
        </p>
      </div>
    </div>
  );
};
