import { PropsWithChildren } from 'react';

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & PropsWithChildren;
export const HeroWrap = (props: Props) => {
  const { className, children } = props;
  return (
    <section className="flex justify-center">
      <div
        className={`${className} md:flex flex justify-center py-3 md:justify-normal md:items-center w-full xl:py-10 xl:justify-center 2xl:h-[600px]`}
      >
        <div className="xl:w-[1100px] 2xl:w-[1380px]">
          <div
            className={
              'md:bg_workforce md:ms-5 xl:ms-0 rounded-xl text-white w-[344px] md:p-4 xl:p-8 grid gap-4 xl:gap-6 md:w-[596px] md:min-h-[273px] xl:w-[700px] xl:min-h-[323px] 2xl:w-[880px] max-md:mx-auto'
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
