import { Button, Label, Modal, Select, Spinner } from "flowbite-react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import driver_icon from "../assets/images/csr_driver.png";
import stem_icon from "../assets/images/csr_stem.png";
import outreach_icon from "../assets/images/csr_outreach.png";
import company_icon from "../assets/images/faq_company_icon.svg";
// import plus_icon from "../assets/images/blue_plus_icon.svg";
// import minus_icon from "../assets/images/blue_minus_icon.svg";
import plus_icon from "../assets/images/faq_plus_icon.svg";
import minus_icon from "../assets/images/faq_minus_icon.svg";
import { Helmet } from "react-helmet";
import { HeroWrap } from "../components/HeroWrap";
export type contactFrm = {
  InputName: string;
  InputEmail: string;
  InputPhone: string;
  CompanyName: string;
  CompanyPhone: string;
  InputMessage: string;
};
type IProp = {
  title: string;
  curIndex: number;
  description: string;
  selected: number;
  selectItem: (nIndex: number) => void;
};
export const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
export const ItemBox = ({
  title,
  description,
  selectItem,
  selected,
  curIndex,
}: IProp) => {
  const isExpanded = curIndex == selected;
  return (
    <div
      className={`${
        isExpanded ? "faq_expanded" : "faq_collapsed"
      } xl:p-6 p-4 cursor-pointer`}
      onClick={() => selectItem(curIndex)}
    >
      <div className="flex space-x-1">
        <img
          src={isExpanded ? minus_icon : plus_icon}
          alt=""
          className="cursor-pointer "
          onClick={() => selectItem(curIndex)}
        />
        <h6
          className={`text-sm ms-4 font-bold xl:text-lg ${
            isExpanded ? "text-white" : "text-my-gray"
          }`}
        >
          {title}
        </h6>
      </div>
      {isExpanded && (
        <p
          className={`mt-2 text-sm xl:text-base ${
            isExpanded ? "text-white" : "text-my-gray"
          } font-extralight`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};
const faqItems = [
  {
    title: "What does ARSOME Technology do?",
    description:
      "We develop personalized AR/VR simulations to increase employee safety, improve personnel readiness, and actually make employee training fun. Our proprietary platforms allow us to simulate workforce activities and tasks with exceptional accuracy. Trainers and managers receive data-driven insights via proprietary AI algorithms and interactive dashboards.",
  },
  {
    title: "Why contract ARSOME Technology?",
    description:
      'We’ve developed 100+ custom AR/VR development training programs for corporations, organizations and Government. We don’t use or bundle pre-packaged solutions; we listen to your needs, observe your existing training programs, and develop personalized training and workforce programs to exceed your expectations. Learn more about our capabilities to ensure we’re the right fit for your project: <a href="/Are_We_A_Fit" class="chapter_bold underline">Are We A Fit? | ARSOME Technology</a>',
  },
  {
    title: "Who contracts ARSOME Technology?",
    description:
      "75% of our clients represent the government and defense. 25% of our clients represent corporations and higher education. Our specialty is bringing to life highly complex, dangerous, or expensive workforce activities with AR/VR simulations.",
  },
  {
    title: "What kind of AR/VR simulations does ARSOME Technology develop? ",
    description:
      'We develop advanced and personalized AR/VR training simulations with exceptional accuracy and AI integration to reduce training cost, improve workplace safety, and prepare personnel for the real thing. Learn more about our capabilities: <a href="/AR_VR_Custom_Development" class="chapter_bold underline">AR/VR Custom Development | ARSOME Technology</a>',
  },
  {
    title:
      "Does ARSOME Technology have a structured project management process?",
    description:
      'Yes. Whether the project is an MVP or developed with field-ready features and functionality, all projects complete a 4-phase project management process; and are managed by a certified PMP manager. Learn more to ensure you align with our project management process: <a href="/Process" class="chapter_bold underline">Process | ARSOME Technology</a>',
  },
  {
    title: "Does ARSOME Technology maintain and improve developed projects?",
    description:
      "Yes. After completing the development of an AR/VR project, we will maintain, troubleshoot and improve the project over time with lessons learned, cutting-edge technology, and training processes to keep you advanced. ",
  },
  {
    title:
      "Does ARSOME Technology have Corporate Social Responsibility (CSR) programs?",
    description:
      'Yes. Corporations can purchase a turn-key program or customize one of our CSR programs reflecting their brand and corporate outreach strategy. Distributed through partner organizations like local schools and community agencies, young people will learn about your brand, company careers, and skills required to succeed. Learn how our CSR programs will create career and brand buzz in your community: <a href="/CSR" class="chapter_bold underline">CSR | ARSOME Technology</a>',
  },
  {
    title: "Does ARSOME Technology invest in start-up and projects?",
    description:
      'Yes. Learn more about our investing model to ensure we’re the right investor to help realize your vision: <a href="/ARSOME_Ventures" class="chapter_bold underline">Ventures | ARSOME Technology</a>',
  },
  {
    title: "When was ARSOME Technology founded and by who?",
    description: `It all started when a student took his professor's winter computer science class. Learn more about our story today: <a href="/Meet_The_Team" class="chapter_bold underline">Meet the Team | ARSOME Technology</a>`,
  },
];
export const FAQ = () => {
  const [curItem, setCurItem] = useState(0);
  const [chapter, setChapter] = useState(0);

  return (
    <div className="min_contact_wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ | ARSOME Technology</title>
        <meta
          name="description"
          content="Can’t find an answer? Please contact support@ARSOME.com or call us at (860-304-7660) "
        />
      </Helmet>
      {/* <section className="grid gap-y-3 max-md:hidden md:block">
        <div>
          <img src={company_icon} alt="" className="mx-auto" />
        </div>
        <div className="grid gap-y-3">
          <h4 className="mx-auto text-sm font-bold text-center xl:text-4xl text-my-gray">
            Have any questions?
          </h4>
          <p className="text-center font-extralight xl:text-lg">
            Can’t find an answer? Please contact support@ARSOME.com or call us
            at (860-304-7660){' '}
          </p>
        </div>
      </section> */}
      <HeroWrap className="max-md:block md:hidden">
        <h6 className="font-bold text-xl text-[#2E90F2] text-center">
          Have any questions?
        </h6>
        <p className=" font-light text-center text-[#383231]">
          Can’t find an answer? Please contact <br /> support@ARSOME.com or call
          us at (860-304-7660){" "}
        </p>
      </HeroWrap>
      <section>
        <div className="mt-4 xl:flex xl:justify-center xl:space-x-3">
          {/* <div className="w-[215px] space-y-2 max-xl:mx-auto">
            <h5 className="text-2xl font-bold text-center">
              Table Of Contents
            </h5>
            <div className="max-xl:flex xl:grid max-xl:space-x-4 xl:space-x-0 max-xl:justify-center xl:justify-start">
              <h6
                className={
                  chapter == 0
                    ? 'chapter_bold text-lg max-md:text-base'
                    : 'text-lg cursor-pointer max-md:text-base'
                }
                onClick={() => {
                  if (chapter != 0) {
                    setChapter(0);
                    setCurItem(0);
                  }
                }}
              >
                General
              </h6>
              <h6
                className={
                  chapter == 1
                    ? 'chapter_bold text-lg max-md:text-base'
                    : 'text-lg cursor-pointer max-md:text-base'
                }
                onClick={() => {
                  if (chapter != 1) {
                    setChapter(1);
                    setCurItem(0);
                  }
                }}
              >
                Services
              </h6>
            </div>
          </div> */}

          <div className="xl:w-[855px] md:w-[713px] max-xl:mx-auto max-md:w-[344px] space-y-3">
            {faqItems.map((_it, idx) => (
              <ItemBox
                key={idx}
                title={_it.title}
                selected={curItem}
                curIndex={idx}
                description={_it.description}
                selectItem={(nIndex) => setCurItem(nIndex)}
              />
            ))}
            {/* {serviceItems.map((_it, idx) => (
                <ItemBox
                  key={idx}
                  title={_it.title}
                  selected={curItem}
                  curIndex={idx}
                  description={_it.description}
                  selectItem={(nIndex) => setCurItem(nIndex)}
                />
              ))
            } */}
          </div>
        </div>
      </section>
    </div>
  );
};
